import React, {FC, Fragment} from 'react';
import Helmet from 'react-helmet';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link} from 'gatsby';

type Props = {
	title: string;
	subTitle?: string;
	subLink?: string;
}

const SubHeader: FC<Props> = ({title, subTitle, subLink}) =>
	<Fragment>
		<Helmet
			title={title}
		/>
		<header id="header"
			className="ex-header">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h1>{title}</h1>
					</div>
				</div>
			</div>
		</header>
		<div className="ex-basic-1">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<Breadcrumb>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{to: '/'}}
							>
								Home
							</Breadcrumb.Item>
							{
								subTitle && subLink ?
									<Fragment>
										<Breadcrumb.Item
											linkAs={Link}
											linkProps={{to: subLink}}>
											{subTitle}
										</Breadcrumb.Item>
										<Breadcrumb.Item active>{title}</Breadcrumb.Item>
									</Fragment> :
									<Breadcrumb.Item active>{title}</Breadcrumb.Item>
							}
						</Breadcrumb>
					</div>
				</div>
			</div>
		</div>
	</Fragment>;

export default SubHeader;
